import { Outlet } from '@remix-run/react';
import { type HeadersFunction } from '@remix-run/server-runtime';

export { AppErrorBoundary as ErrorBoundary } from '~app/components/error/app-error-boundary';

export const headers: HeadersFunction = ({ parentHeaders }) => {
  return parentHeaders;
};

export default function App() {
  return (
    <>
      <Outlet />
    </>
  );
}
